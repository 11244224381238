import React from "react"

const DynamicImages = ({ arrayUp, arrayDown }) => {
  // Function to create and render image elements
  const renderImages = (name, type) => {
    return (
      <div key={`${name}${type}`} className="image">
        <img
          src={`${name}${type}.svg`}
          alt={`chain ${name} ${type}`}
          title={`chain ${name} ${type}`}
          style={{ height: "28px" }}
        />
      </div>
    )
  }

  console.log("Array Up:", arrayUp)
  console.log("Array Down:", arrayDown)

  return (
    <div className="chain-status-container-mobile">
      {/* Render 'up' images */}
      {arrayUp.map((imageName) => renderImages(imageName, "up"))}

      {/* Render 'down' images */}
      {arrayDown.map((imageName) => renderImages(imageName, "down"))}
    </div>
  )
}

export default DynamicImages
