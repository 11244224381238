export const redeliveryRelayAddress =
  "0x000000000034cEe85E643e3aA7281341580f9713"

export const redeliveryRelayABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "requestId",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "address",
        name: "caller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint96",
        name: "value",
        type: "uint96",
      },
      {
        indexed: false,
        internalType: "address",
        name: "refundAddress",
        type: "address",
      },
    ],
    name: "ArrngRedeliveryRequest",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "arrngRequestId_",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "refundAddress_",
        type: "address",
      },
    ],
    name: "requestRedelivery",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "arrngRequestId_",
        type: "uint256",
      },
    ],
    name: "requestRedelivery",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
]
