import React from "react"
import "./App.css" // You can create a CSS file to style the components
import DynamicImagesMobile from "./DynamicImagesMobile" // Import the new component
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query"

const Status = () => {
  const { data } = useQuery({
    queryFn: async () => {
      const fetchURL = new URL("https://api.arrng.io/getNetworkStatus")

      console.log(fetchURL.href)

      const response = await fetch(fetchURL.href)
      const json = await response.json()
      console.log(json)
      return json
    },
  })

  // Check if data is available
  if (!data) {
    return <div>Loading...</div>
  }

  // Extract the arrays from the data object
  const {
    sextantServiceUp,
    sextantServiceDown,
    crowsnestServiceUp,
    crowsnestServiceDown,
    lanternServiceUp,
    lanternServiceDown,
  } = data

  return (
    <div className="status-bar">
      <div className="status-boxes-mobile" style={{ flex: 1 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ARRNG NETWORK STATUS
        </div>
        <div className="container-mobile">
          <div
            className="image-container-mobile"
            title="crowsnest - watches chains 24/7 for arrng requests"
          >
            {/* Add your image here */}
            <img src="icoCrow.png" alt="crowsnest" />
          </div>
          <DynamicImagesMobile
            arrayUp={crowsnestServiceUp}
            arrayDown={crowsnestServiceDown}
          />
        </div>
        <div className="container-mobile">
          <div
            className="image-container-mobile"
            title="lantern - serves encrypted arrng data over SSL"
          >
            {/* Add your image here */}
            <img src="icoLantern.png" alt="lantern" />
          </div>
          <DynamicImagesMobile
            arrayUp={lanternServiceUp}
            arrayDown={lanternServiceDown}
          />
        </div>
        <div className="container-mobile">
          <div
            className="image-container-mobile"
            title="sextant - watches chains, aggregating report data and checking arrng health"
          >
            {/* Add your image here */}
            <img src="icoSextant.png" alt="sextant" />
          </div>
          <DynamicImagesMobile
            arrayUp={sextantServiceUp}
            arrayDown={sextantServiceDown}
          />
        </div>
      </div>
    </div>
  )
}
const queryClient = new QueryClient()

const StatusView = () => (
  <QueryClientProvider client={queryClient}>
    <Status />
  </QueryClientProvider>
)

export default StatusView
