export const controllerAddress = "0x000000000000f968845afB0B8Cf134Ec196D38D4"

export const resultsTopic = [
  "0x2f4e34c2e65889cb3bd8836a6aa41071c3b123bc7277a740070decb05c144f00",
]

export const controllerABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "owner_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "caller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "requestId",
        type: "uint256",
      },
    ],
    name: "ArrngRefundInsufficientTokenForGas",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "caller",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint64",
        name: "requestId",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "method",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "numberOfNumbers",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "minValue",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "maxvalue",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "ethValue",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "address",
        name: "refundAddress",
        type: "address",
      },
    ],
    name: "ArrngRequest",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "requestTxnHash",
        type: "bytes32",
      },
    ],
    name: "ArrngResponse",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint128",
        name: "requestId",
        type: "uint128",
      },
      {
        indexed: false,
        internalType: "uint128",
        name: "feeCharged",
        type: "uint128",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "randomNumbers",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "string",
        name: "apiResponse",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "apiSignature",
        type: "string",
      },
    ],
    name: "ArrngServed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "ensName",
        type: "string",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "ensNameHash",
        type: "bytes32",
      },
    ],
    name: "ENSNameSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newENSReverseRegistrar",
        type: "address",
      },
    ],
    name: "ENSReverseRegistrarSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "newNumberLimited",
        type: "uint256",
      },
    ],
    name: "MaximumNumberOfNumbersSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "minimumNativeToken",
        type: "uint256",
      },
    ],
    name: "MinimumNativeTokenSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "oracle",
        type: "address",
      },
    ],
    name: "OracleAddressSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "treasury",
        type: "address",
      },
    ],
    name: "TreasuryAddressSet",
    type: "event",
  },
  {
    inputs: [],
    name: "arrngRequestId",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ensReverseRegistrar",
    outputs: [
      {
        internalType: "contract IENSReverseRegistrar",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maximumNumberOfNumbers",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minimumNativeToken",
    outputs: [
      {
        internalType: "uint176",
        name: "",
        type: "uint176",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "from_",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "onERC721Received",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "oracleAddress",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "numberOfNumbers_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minValue_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxValue_",
        type: "uint256",
      },
    ],
    name: "requestRandomNumbersInRange",
    outputs: [
      {
        internalType: "uint256",
        name: "uniqueID_",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "numberOfNumbers_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minValue_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxValue_",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "refundAddress_",
        type: "address",
      },
    ],
    name: "requestRandomNumbersInRange",
    outputs: [
      {
        internalType: "uint256",
        name: "uniqueID_",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "numberOfNumbers_",
        type: "uint256",
      },
    ],
    name: "requestRandomWords",
    outputs: [
      {
        internalType: "uint256",
        name: "uniqueID_",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "numberOfNumbers_",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "refundAddress_",
        type: "address",
      },
    ],
    name: "requestRandomWords",
    outputs: [
      {
        internalType: "uint256",
        name: "uniqueID_",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "numberOfNumbers_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minValue_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxValue_",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "method_",
        type: "uint32",
      },
    ],
    name: "requestWithMethod",
    outputs: [
      {
        internalType: "uint256",
        name: "uniqueID_",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "numberOfNumbers_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minValue_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxValue_",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "refundAddress_",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "method_",
        type: "uint32",
      },
    ],
    name: "requestWithMethod",
    outputs: [
      {
        internalType: "uint256",
        name: "uniqueID_",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "arrngRequestId_",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "callingAddress_",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "requestTxnHash_",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "responseCode_",
        type: "uint256",
      },
      {
        internalType: "uint256[]",
        name: "randomNumbers_",
        type: "uint256[]",
      },
      {
        internalType: "address",
        name: "refundAddress_",
        type: "address",
      },
      {
        internalType: "string",
        name: "apiResponse_",
        type: "string",
      },
      {
        internalType: "string",
        name: "apiSignature_",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "feeCharged_",
        type: "uint256",
      },
    ],
    name: "serveRandomness",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "ensName_",
        type: "string",
      },
    ],
    name: "setENSName",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "ensRegistrar_",
        type: "address",
      },
    ],
    name: "setENSReverseRegistrar",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "maxNumbersPerTxn_",
        type: "uint16",
      },
    ],
    name: "setMaximumNumberOfNumbers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint176",
        name: "minNativeToken_",
        type: "uint176",
      },
    ],
    name: "setMinimumNativeToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "oracle_",
        type: "address",
      },
    ],
    name: "setOracleAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "treasury_",
        type: "address",
      },
    ],
    name: "setTreasuryAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "treasuryAddress",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "erc20Address_",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount_",
        type: "uint256",
      },
    ],
    name: "withdrawERC20",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "erc721Address_",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "tokenIDs_",
        type: "uint256[]",
      },
    ],
    name: "withdrawERC721",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount_",
        type: "uint256",
      },
    ],
    name: "withdrawNativeToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
]
